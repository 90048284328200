@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0F0F0F;
  color: var(--tg-theme-text-color);
}


/* .swiper-button-next,
.swiper-button-prev{
  top: 115px !important;
} */